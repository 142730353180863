// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

// eslint-disable-next-line no-unused-vars
import Splide from '@splidejs/splide';
// eslint-disable-next-line no-unused-vars
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/* global ajax_object */

function initCustomSelect() {
  $('select').each(function () {
    var $this = $(this),
      selectClasses = $this.attr('class'),
      numberOfOptions = $(this).children('option').length;
    if ($this.parent('.select').length) {
      return;
    }

    $this
      .addClass('hide')
      .wrap('<div class="select"></div>')
      .after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect
      .text($this.children('option:selected').text())
      .addClass(selectClasses);

    var $list = $('<ul />', {
      class: 'select-options',
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val(),
      }).appendTo($list);
    }

    $styledSelect.on('click', function (e) {
      e.stopPropagation();
      $('div.select-styled.active')
        .not(this)
        .each(function () {
          $(this).removeClass('active').next('ul.select-options').hide();
        });
      $(this).toggleClass('active').next('ul.select-options').toggle();
      $(this).parents('.gfield').toggleClass('active');
    });

    $list.children('li').on('click', function (e) {
      e.stopPropagation();
      $styledSelect
        .text($(this).text())
        .removeClass('active')
        .next('.select-options')
        .hide();
      $this.val($(this).attr('rel')).trigger('change');
      $(this).removeClass('active').next('ul.select-options').toggle();
      $(this).parents('.gfield').removeClass('active');
    });

    $(document).on('click', function () {
      if ($styledSelect.hasClass('active')) {
        $styledSelect.removeClass('active');
        $list.hide();
      }
    });
  });
}

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

//AJAX Resources Posts Loader

let perpage = 6;

function getPostsByCat(response, taxonomy = false, find = '') {
  let offset = taxonomy
    ? 0
    : $('.module-resources-filter .posts__wrapper-block').length;
  let categorys = $('.module-resources-filter .sorting-posts__tax')
    .find('.active')
    .data('category');
  console.log(categorys);

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: ajax_object.ajax_url,
    data: {
      action: 'cat_filtered',
      offset: offset,
      category: categorys,
      find: find,
      ppp: perpage,
    },
    success: function (response) {
      if (taxonomy) {
        $('#ajax-resources').html(response.html);
        $('.more-posts').show();
      } else {
        $('#ajax-resources').append(response.html);
      }
      if ($('.posts__wrapper-block').length >= response.post_count) {
        $('.more-posts').hide();
      }
    },
    error: function (result) {
      console.warn(result);
    },
  });
  return false;
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.posts__list-select, .js-category').on('click', '.init', function () {
    $(this).closest('ul').children('li:not(.init)').toggle();
    $(this).closest('ul').toggleClass('opened');
    $(this).closest('ul').children('li.hide').removeClass('hide');
  });

  $(document).on('click', function (e) {
    if ($(e.target).is('.posts__list-select, .init') === false) {
      $('.posts__list-select').find('li.posts__tax-link').hide();
      $('.posts__list-select').removeClass('opened');
    }
  });

  $('#cpt-searchform').on('submit', function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).find('[name="find"]').val()) {
      getPostsByCat('response', true, $(this).find('[name="find"]').val());
    }
  });

  /**
   * Dropdown List
   */

  // $('.posts__list-select').on('click', '.init', function () {
  //   $(this).closest('.posts__list-select').children('li:not(.init)').toggle();
  //   $(this).closest('ul').children('li.hide').removeClass('hide');
  // });

  var allOptions = $('.posts__list-select, .js-category').children(
    'li:not(.init)'
  );
  $('.posts__list-select, .js-category').on(
    'click',
    'li:not(.init)',
    function () {
      allOptions.removeClass('selected');
      $(this).addClass('selected');
      $(this).closest('ul').toggleClass('opened');
      $('.posts__list-select, .js-category')
        .children('.init')
        .html($(this).html());
      allOptions.toggle();
    }
  );
  // $('.sorting-posts__tax').click(function () {
  //   $('.posts__list-select').toggleClass('arrow');
  // });

  // var allOptions = $('.posts__list-select').children('li:not(.init)');
  // $('.posts__list-select').on('click', 'li:not(.init)', function () {
  //   allOptions.removeClass('selected');
  //   $(this).addClass('selected');
  //   $('.posts__list-select').children('.init').html($(this).html());
  //   allOptions.toggle();
  // });

  /**
   * AJAX CATEGORY FILTER
   */

  function loadPosts(response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.posts__wrapper-block').length;
    let category = $('.sorting-posts__tax').find('.active').data('category');

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        action: 'filtration_ajax',
        offset: offset,
        category: category,
        ppp: perpage,
      },
      success: function (response) {
        if (taxonomy) {
          $('#ajax-posts').html(response.html);
          $('.more-posts').show();
        } else {
          $('#ajax-posts').append(response.html);
        }
        if ($('.posts__wrapper-block').length >= response.post_count) {
          $('.more-posts').hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.more-posts').on('click', function () {
    loadPosts('response');
  });
  $('.posts__tax-link').click(function (e) {
    e.preventDefault();
    $('.posts__tax-link').removeClass('active');
    $(this).addClass('active');
    loadPosts('response', true);
  });

  $('.module-resources-filter .more-posts').on('click', function () {
    getPostsByCat('response');
  });
  $('.module-resources-filter .posts__tax-link').click(function (e) {
    e.preventDefault();
    $('.module-resources-filter .posts__tax-link').removeClass('active');
    $(this).addClass('active');
    getPostsByCat('response', true);
  });
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  if ($('section').hasClass('gallery-slider')) {
    new Splide('.splide', {
      arrows: false,
      pagination: false,
      type: 'loop',
      drag: 'free',
      clones: 4,

      // resetProgress: false,
      // slideFocus: true,
      // focusableNodes: 'a',

      autoScroll: {
        speed: 1,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll });
  }
  // $('.gallery-slider__wrapper').slick({
  //   speed: 10000,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   cssEase: 'linear',
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   infinite: true,
  //   swipeToSlide: true,
  //   centerMode: true,
  //   arrows: false,
  //   pauseOnHover: false,
  //   responsive: [
  //     {
  //       breakpoint: 2500,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 1300,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // });

  /**
   * First LI item placeholder
   */
  // console.log(document.querySelector('li').textContent); // Doesn't see the CSS content
  // setTimeout(function () {
  //   document.querySelector('li').textContent = 'test';
  // }, 2000);

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
    initCustomSelect();
    $(document).on('click', function (e) {
      if ($(e.target).is('.form-style .ginput_container_select') === false) {
        $('.form-style .ginput_container_select').removeClass('active');
        $('.form-style .ginput_container_select')
          .parents('.gfield')
          .removeClass('active');
      }
    });

    // $(function () {
    //   $('.form-style .ginput_container_select').on('click', function () {
    //     $(this).toggleClass('active');
    //     $(this).parents('.gfield').toggleClass('active');
    //   });
    //   $(document).on('click', function (e) {
    //     if ($(e.target).is('.form-style .ginput_container_select') === false) {
    //       $('.form-style .ginput_container_select').removeClass('active');
    //       $('.form-style .ginput_container_select')
    //         .parents('.gfield')
    //         .removeClass('active');
    //     }
    //   });
    // });
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
